<!--
 * @Author: wxb
 * @Date: 2021-07-15 23:41:01
 * @LastEditTime: 2021-07-16 00:19:32
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Subject\preExhiDetail.vue
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='6'></headerNav>
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/special'}">专题</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/preExhi'}">拟办展会</el-breadcrumb-item>
        <el-breadcrumb-item>拟办展会详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="notice-panel"
           v-show="canSee">
        <div class="notice-title">{{exhibitionInfo.exhibitionname}} </div>

        <div class="notice-detail">
          <table class="table table-border table-bordered">
            <tr>
              <td class="text-r notice-content-title"
                  width="150">展馆名称</td>
              <td class="notice-content-info">{{exhibitionInfo.exhibitionhall}} </td>
            </tr>
            <tr>
              <td class="text-r notice-content-title">展馆地址</td>
              <td class="notice-content-info">{{exhibitionInfo.exhibitionaddress}} </td>
            </tr>
            <tr>
              <td class="text-r notice-content-title">展会网址</td>
              <td class="notice-content-info">
                <el-link :underline="false"
                         type="primary"
                         :href="exhibitionInfo.schelink"
                         target="_blank">{{exhibitionInfo.schelink}}</el-link>
              </td>
            </tr>
            <tr>
              <td class="text-r notice-content-title">开始时间</td>
              <td class="notice-content-info">{{exhibitionInfo.starttime}} </td>
            </tr>
            <tr>
              <td class="text-r notice-content-title">结束时间</td>
              <td class="notice-content-info">{{exhibitionInfo.endtime}} </td>
            </tr>
          </table>
        </div>
      </div>
      <viporlogin bgStr="5"
                  :type="viporlogin"
                  v-show="!canSee"></viporlogin>
      <dialogviporlogin ref="dialogviporlogin"></dialogviporlogin>
    </div>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import viporlogin from '@/components/viporlogin'
import { mapGetters } from 'vuex'
import { proposedExhibitionDetails } from '@/api/subject'
import dialogviporlogin from '@/components/dialogviporlogin'
export default {
  name: 'preExhiDetail',
  components: {
    headerNav,
    footerNav,
    viporlogin,
    dialogviporlogin
  },
  data() {
    return {
      viporlogin: 'login',
      canSee: true,
      id: '',
      exhibitionInfo: {}
    }
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  methods: {
    authCheck() {
      const authObj = authenticate('preExhiDetail', this.memberType)
      if (authObj.flag) {
        // 调用查询列表接口
        this.initPage()
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    },
    initPage() {
      const params = {
        id: this.id
      }
      proposedExhibitionDetails(params).then(res => {
        if (res.ok) {
          this.exhibitionInfo = res.data
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted() {
    const id = this.$route.params.id
    this.id = id
    if (getToken()) {
      if (this.memberType === 'tourist') {
        this.$store.dispatch('getMemberInfo').then(res => {
          this.authCheck()
        })
      } else {
        this.authCheck()
      }
    } else {
      this.authCheck()
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}

.notice {
  &-panel {
    width: 144rem;
    margin: 0 auto;
    min-height: 50rem;
    border: 1px solid #eee;
    padding: 15px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }

  &-title {
    font-size: 16px;
    text-align: center;
    margin-bottom: 10px;
  }

  &-content-title {
    background: #f4f4ff;
  }
}
</style>
